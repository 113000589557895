const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6959271', space_js: '//sfzpgbz.gztechsw.com/source/rml_u/j/production/ub_nv.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6959272', space_js: '//sfzpgbz.gztechsw.com/production/s/static/n/resource/m_vkvc/common/oc.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6959273', space_js: '//sfzpgbz.gztechsw.com/source/t/source/o_nwlwd_p/g.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6959274', space_js: '//sfzpgbz.gztechsw.com/site/up/oxm/xe_q/openjs/l.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Home_native: NATIVE_1,
    Home_native_center1: NATIVE_2,
    Detail_inter: INTER,
    Detail_banner: BANNER,
    Detail_native: NATIVE_1,
    Detail_native_center1: NATIVE_2,
    Result_inter: INTER,
    Result_banner: BANNER,
    Result_native: NATIVE_1,
    Result_native_center1: NATIVE_2,
};
