<script setup lang="ts">
import { ref, nextTick, provide } from 'vue';
const isRouterAlive = ref<boolean>(true);

const reload = () => {
    isRouterAlive.value = false;
    nextTick(() => {
        isRouterAlive.value = true;
    })
}
provide('reload', reload);
</script>

<template>
    <div class="app">
        <router-view v-if="isRouterAlive"/>
    </div>
</template>

<style lang="less">
.app {
    width: 750px;
    min-height: 100vh;
    margin: 0 auto;
}
</style>
